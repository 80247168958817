import React, { useState } from "react"
import styled from "styled-components"
import Slider from "react-slick"

import Modal from "react-bootstrap/Modal"
import LeftArrow from "../../static/arrow(l).svg"
import RightArrow from "../../static/arrow(R).svg"
import Mainslide1 from "../../static/main/main1slide.mp4"
import Video_slide from "../mediaSlide"
import "../../style/modal.css"
const Container = styled.div`
  video {
    width: 58vw;
    height: auto;
  }

  .div-size img {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translate(-50%);
  }

  img {
    width: 100%;
    height: auto;
  }
  .div-size iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .div-size {
    position: relative;
    width: 100vw;
    padding-bottom: 56.25%;
    margin: 0 auto;
  }
  .slide-box {
    margin: 0 auto;
    width: 100vw;
  }

  .slick-list {
    padding: 0 60px !important;
  }

  .slick-slide {
    width: 60vw !important;
    opacity: 0.4;
    padding: 0 1vw;
  }

  .slick-track {
    margin: auto;
  }

  .slick-center {
    opacity: 1;
  }

  .slick-prev {
    left: 24vw;
    z-index: 99;
  }

  .slick-next {
    right: 24vw;
    z-index: 99;
  }

  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  .show-all-video {
    display: block;
    margin: 2vw auto;
    border: none;
    border-radius: 100px;
    background-color: white;
    box-shadow: 0px 0px 8px darkgrey;
    padding: 0.7vw 2vw 0.5vw;
    font-size: 1vw;
    font-weight: bold;
    cursor: pointer;
  }

  .slick-dots li.slick-active button:before {
    color: #fff !important;
  }
  .slick-dots li button:before {
    color: #fff;
    font-size: 1.2vw;
    opacity: 1;
  }
  .slick-dots {
    bottom: 2vw;
  }

  .slick-dots li {
    width: 1.5vw;
  }
  @media screen and (max-width: 480px) {
    video {
      width: 100vw;
    }
    .slick-slide {
      width: 100vw !important;
      padding: 0;
      opacity: 1;
    }

    .slick-list {
      padding: 0 !important;
    }

    .slick-prev {
      left: 5vw;
    }

    .slick-next {
      right: 3vw;
    }

    .show-all-video {
      margin: 4vw auto;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.23);
      padding: 2vw 5vw 1.5vw;
      font-size: 4vw;
    }

    .slick-dots li {
      width: 3vw;
    }

    .slick-dots li button:before {
      font-size: 3vw;
    }

    .slick-dots {
      bottom: 2.5vw;
    }
  }
`

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "2vw",
        zIndex: 99,
        color: "#fff",
      }}
      onClick={onClick}
    >
      <img style={{ width: "2vw" }} src={RightArrow} alt="화살표" />
    </div>
  )
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        zIndex: 99,
        color: "#fff",
      }}
      onClick={onClick}
    >
      <img
        style={{ width: "2vw", float: "right" }}
        src={LeftArrow}
        alt="화살표"
      />
    </div>
  )
}

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 1,
  speed: 500,
  variableWidth: true,
  variableHeight: true,
  dots: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        centerMode: false,
        centerPadding: 0,
        variableWidth: true,
        variableHeight: true,
      },
    },
  ],
}

const Main1 = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const goEventPage = () =>
    window.open(
      "https://samlab.notion.site/100-a6297661c9204b34999342e0dedcae51"
    )
  return (
    <Container>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <div className="slide-box">
        <Slider {...settings}>
          <div className="div-size">
            <img
              onClick={() => goEventPage()}
              src="https://user-images.githubusercontent.com/82793902/155943117-b48022db-ef53-433c-b0de-476f0512de51.png"
            />
          </div>
          <div className="div-size">
            {/* <video src="https://samstoragekr.s3.ap-northeast-2.amazonaws.com/saemcafe.com_main_video1.mp4" type="video/mp4" autoPlay muted playsinline="true" loop></video> */}
            <iframe src="https://youtube.com/embed/vYi2n6o1858?autoplay=1&mute=1&rel=0"></iframe>
          </div>
          <div className="div-size">
            <iframe src="https://www.youtube.com/embed/fczsu6zysKw?mute=1&rel=0"></iframe>
          </div>
          <div className="div-size">
            <iframe src="https://www.youtube.com/embed/7sdnfeK5zRg?mute=1&rel=0"></iframe>
          </div>
          <div className="div-size">
            <iframe src="https://www.youtube.com/embed/jGxtToqQrJQ?mute=1&rel=0"></iframe>
          </div>
          <div className="div-size">
            <iframe src="https://www.youtube.com/embed/SeKG4iKIGvg?mute=1&rel=0"></iframe>
          </div>
        </Slider>
      </div>
      <button className="show-all-video" onClick={handleShow}>
        영상 목록 전체 보기
      </button>
      <Modal show={show} onHide={handleClose} className="width-60vw">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Video_slide />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default Main1
