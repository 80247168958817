import React, { useState } from "react"
import styled from "styled-components"

import Main2_image from "../../static/main/main2.png"
import Main2_image_480 from "../../static/main/main2-480.png"
import ImgModal from "../imagemodal"
import Modal from "react-bootstrap/Modal"
import popup_content from "../../static/popup-img.jpg"
import Popup_ans from "../../pages/popup-answer"
import logo from "../../static/logo.png"

import "../../style/modal.css"
import { Link } from "gatsby"
const Container = styled.div`
  a {
    text-decoration: none;
  }
  .main2-back-image img {
    width: 100vw;
  }

  .main2-title-text {
    font-family: "Spoqa Han Sans Neo", "Spoqa Han Sans JP", sans-serif;
    font-weight: 900;
    font-size: 3vw;
    text-align: center;
    color: #fff;
    span {
      color: #fcaa2e;
      background: transparent;
    }
    .upper-text {
      font-family: "Cafe24Shiningstar";
      color: #fff;
      font-weight: 500;
      font-size: 2.5vw;
      line-height: 1.8;
    }
  }

  .main2-sub-title {
    font-family: "Medium";
    text-align: center;
    font-size: 1vw;
    color: #fff;
  }

  // .main2-sub-title span {
  //   font-weight: 900;
  // }

  .main2-detail-btn {
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    padding: 1vw 3vw 0.8vw;
    font-size: 1vw;
    font-weight: bold;
  }

  .main2-front-text {
    width: 100vw;
    height: 17.4vw;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    padding: 2vw;
  }

  .popup_img img {
    width: 50vw;
    display: block;
  }

  .popup_btn {
    width: 15vw;
    position: absolute;
    bottom: 0;
    margin: 0 0 3vw -7.5vw;
    display: block;
    border: none;
    left: 25vw;
    padding: 1.2vw 0 1vw;
    border-radius: 100px;
    background-color: #fff;
    font-weight: bold;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  .background-color {
    background-color: #5a1ac1;
    height: 18vw;
  }

  @media screen and (max-width: 480px) {
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
      height: 58vw;
    }

    .main2-title-text {
      font-size: 6vw;
      line-height: 1.7;
      width: 90vw;
      .upper-text {
        font-size: 6.5vw;
        line-height: 0.8;
      }
    }

    .main2-sub-title {
      font-size: 3vw;
      margin: 2.5vw 0 5vw;
      line-height: 1.38;
    }

    .main2-front-text {
      padding: 9.5vw;
    }

    .main2-detail-btn {
      padding: 2.5vw 5vw 1.5vw;
      font-size: 4vw;
    }

    .popup_img img {
      width: 100vw;
      display: block;
      margin: 6vw 0 6vw;
    }

    .popup_btn {
      padding: 1.8vw 4vw 1vw;
      left: 40.5vw;
      margin: 0 auto 8vw;
    }
  }
`

const Main2 = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const openModal = () => {
    setModalVisible(true)
  }
  const closeModal = () => {
    setModalVisible(false)
  }

  const nextModal = () => {
    setModalVisible(false)
    handleShow()
  }
  return (
    <Container>
      <div className="main2-back-image">
        <div className="main2-front-text">
          <p className="main2-title-text">
            <span className="upper-text">일반 스터디카페 창업 하세요?</span>
            <br />
            이제는 <span>'관리형'</span> 스터디 카페 하세요.
          </p>
          <p className="main2-sub-title">
            <span>* 무료 상담</span> 받고 신규가맹/업종변경{" "}
            {/* <br className="show-480" /> */}
            <span>특별 창업혜택</span>을 받아보세요
          </p>
          <Link to="/franchise">
            <button
              className="main2-detail-btn"
              //  onClick={openModal}
            >
              자세히 보기
            </button>
          </Link>
        </div>
        <div className="background-color hide-480"></div>
        <div className="background-color show-480"></div>
        {/* <img
          className="hide-480"
          src={Main2_image}
          alt="두번째 배경이미지"
        ></img>
        <img
          className="show-480"
          src={Main2_image_480}
          alt="두번째 배경이미지"
        ></img> */}
        {modalVisible && (
          <ImgModal
            visible={modalVisible}
            closable={true}
            maskClosable={true}
            onClose={closeModal}
          >
            <button className="popup_btn" onClick={nextModal}>
              문의하기
            </button>
            <div className="popup_img">
              <img src={popup_content} />
            </div>
          </ImgModal>
        )}

        <Modal className="answer-modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body style={{ padding: 0 }}>
            <Popup_ans />
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  )
}

export default Main2
