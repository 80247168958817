import React from "react"
import styled from "styled-components"
import { Fade } from "react-awesome-reveal"

import Main4image from "../../static/main/main4.png"
import Main4image2 from "../../static/main/main4-480.png"

const Container = styled.div`
  padding: 4vw 0 5vw;
  background-color: #f7f7f7;
  border-bottom: dotted 1px #877c7a;
  .main4-textPart {
    font-weight: bold;
    width: 70vw;
    margin: 0 auto;
  }
  .main4-miniTitle {
    font-size: 1.7vw;
    color: #d96e62;
  }

  .main4-title {
    font-size: 3vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  .main4-text {
    font-size: 1.3vw;
    line-height: 1.79;
  }

  .main4-text span {
    color: #d96e62;
  }

  .main4-imagePart img {
    width: 90vw;
    margin: 4vw auto 0;
  }
  .show-480 {
    display: none;
  }
  .hide-480 {
    display: block;
  }

  @media screen and (max-width: 480px) {
    padding: 10vw 0;
    .main4-textPart {
      width: 80vw;
    }

    .main4-miniTitle {
      font-size: 4.3vw;
    }
    .main4-title {
      font-size: 6.5vw;
      line-height: 1.25;
    }

    .main4-imagePart img {
      width: 100vw;
      margin: 0 auto;
    }

    .show-480 {
      display: block;
    }
    .hide-480 {
      display: none;
    }

    .main4-text-part-480 {
      width: 86vw;
      margin: 3vw auto 1vw;
    }

    .main4-text-part-480 p {
      font-weight: bold;
      font-size: 4vw;
      line-height: 1.67;
    }
  }
`

const Main4 = () => {
  return (
    <Container>
      <Fade>
        <div className="main4-textPart">
          <p className="main4-miniTitle">우수기술기업(TCB) T-4 등급 획득</p>
          <p className="main4-title">
            자체 개발
            <br className="show-480" />
            키오스크와 클라우드
          </p>
          <p className="main4-text hide-480">
            공간샘 키오스크의 핵심은 단순히 직원을 대체하는 것이 아닌, <br />
            <span>직원의 서비스를 대체</span>하는 것입니다.
          </p>
        </div>
      </Fade>
      <Fade direction="up" delay={-300}>
        <div className="main4-imagePart">
          <img className="hide-480" src={Main4image} alt="자체 개발 키오스크" />
          <img
            className="show-480"
            src={Main4image2}
            alt="자체 개발 키오스크"
          />
        </div>
      </Fade>
      <Fade>
        <div className="main4-text-part-480 show-480">
          <p>•실시간 연동을 통한 매장 현황 조회 및 제어</p>
          <p>•생체 인식으로 무단이용자 / 시간초과이용자 통제</p>
          <p>•양방향 키오스크로 더욱 정밀한 고객 데이터 분석</p>
        </div>
      </Fade>
    </Container>
  )
}

export default Main4
