import React from "react"
import styled from "styled-components"
import { Fade } from "react-awesome-reveal"

import Main6image from "../../static/main/main6.png"
import Main6image_480 from "../../static/main/main6-480.png"
const Container = styled.div`
  padding: 14vw 0 10vw;
  background-color: #f7f7f7;
  .main6-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main6-imagePart img {
    width: 49vw;
    padding-left: 7vw;
  }

  .main6-textPart {
    font-weight: bold;
    margin-right: 15vw;
  }

  .main6-mini-title {
    font-size: 1.7vw;
    color: #d96e62;
  }

  .main6-title {
    font-size: 3vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
    line-height: 1.2;
  }

  .main6-text {
    font-size: 1.3vw;
    line-height: 1.79;
  }

  .main6-text span {
    color: #d96e62;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .main6-part {
      flex-direction: column-reverse;
    }

    .main6-mini-title {
      font-size: 4.3vw;
    }

    .main6-title {
      font-size: 6.5vw;
    }

    .main6-textPart {
      font-weight: bold;
      width: 80vw;
      margin-right: 0;
    }

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .main6-text {
      font-size: 4.3vw;
      font-weight: bold;
      width: 80vw;
      line-height: 1.38;
    }

    .main6-text span {
      color: #000000;
    }

    .main6-imagePart img {
      width: 90vw;
      padding-left: 0;
      margin: 5vw;
    }
  }
`

const Main6 = () => {
  return (
    <Container>
      <div className="main6-part">
        <p className="main6-text  show-480">
          특별한 <span>방음 기술</span>과 프리미엄
          <br />
          인테리어가 적용된{" "}
          <span>
            5가지의
            <br /> 학습공간
          </span>
          을 통해 극대화 된 학습 효율을
          <br />
          경험할 수 있습니다.
        </p>
        <Fade direction="up" delay={-200}>
          <div className="main6-imagePart">
            <img className="hide-480" src={Main6image} alt="main6" />
            <img className="show-480" src={Main6image_480} alt="main6" />
          </div>
        </Fade>
        <Fade>
          <div className="main6-textPart">
            <p className="main6-mini-title">학습 공간 구성</p>
            <p className="main6-title">
              '프리미엄 학습공간'
              <br />
              검증된 기술로
              <br className="hide-480" />
              완성됩니다
            </p>
            <p className="main6-text hide-480">
              특별한 <span>방음 기술</span>과 프리미엄
              <br />
              인테리어가 적용된{" "}
              <span>
                5가지의
                <br /> 학습공간
              </span>
              을 통해 극대화 된 학습 효율을
              <br />
              경험할 수 있습니다.
            </p>
          </div>
        </Fade>
      </div>
    </Container>
  )
}

export default Main6
