import React from "react"
import styled from "styled-components"
import { Fade } from "react-awesome-reveal"
import Slider from "react-slick"

const Container = styled.div`
  padding: 10vw 0 6vw;

  .main7-title-text {
    font-size: 4vw;
    font-weight: 900;
    text-align: center;
    margin-bottom: -1.4vw;
  }

  .main7-underline-pink {
    width: 30vw;
    height: 1.2vw;
    background-color: rgba(217, 110, 98, 0.3);
    margin: 0 auto;
  }

  .main7-sub-text {
    text-align: center;
    margin: 4vw auto;
    line-height: 1.5;
    font-size: 1.2vw;
  }

  .main7-sub-text span {
    font-weight: bold;
  }

  .city img {
    width: 23vw;
    height: 17.5vw;
    margin-left: auto;
    margin-right: auto;
  }

  .city-title {
    font-size: 1vw;
    position: absolute;
    background-color: #fff;
    padding: 1vw 1.2vw 0.7vw;
    border-radius: 100px;
    margin-top: 1.1vw;
    margin-left: 2vw;
    vertical-align: middle;
  }

  .city-title span {
    font-weight: bold;
  }

  .city-list-btn {
    display: block;
    margin: 5vw auto 4vw;
    border: none;
    border-radius: 100px;
    background-color: white;
    box-shadow: 0px 0px 8px darkgrey;
    padding: 0.7vw 2vw 0.5vw;
    font-size: 1vw;
    font-weight: bold;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }
  @media screen and (max-width: 480px) {
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .main7-title-text {
      font-size: 8vw;
    }

    .main7-underline-pink {
      width: 61vw;
      height: 3.2vw;
      margin: -3.5vw auto 0;
    }
    .main7-sub-text {
      font-size: 3.7vw;
    }

    .horizontal-item img {
      width: 76vw;
      height: 50vw;
    }

    .horizontal-slide .slick-slide {
      width: 81vw;
    }

    .horizontal-slide {
      margin-left: 12vw;
    }

    .horizontal-item {
      width: 76vw !important;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.18);
      margin-bottom: 1vw;
      margin-left: 1vw;
      padding-bottom: 1.5vw;
    }

    .horizontal-item-title {
      font-size: 4vw;
      line-height: 1.5;
      margin-top: 3vw;
      margin-left: 6vw;
    }

    .horizontal-item-title span {
      font-weight: bold;
      line-height: 1.5;
    }

    .new-branch {
      font-size: 3vw;
      background-color: #d96e62;
      color: #fff;
      padding: 0.8vw 2vw 0.6vw;
      border-radius: 500px;
      vertical-align: text-top;
    }
    .city-list-btn {
      padding: 2.8vw 6vw 2vw;
      font-size: 4vw;
    }
  }
`

const settings = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 3000,
  cssEase: "linear",
}

const settings2 = {
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1000,
  cssEase: "linear",
  autoplay: true,
  autoplaySpeed: 3000,
  variableWidth: true,
  variableHeight: true,
}

const Main7 = ({ branchlist }) => {
  branchlist.length = 5
  return (
    <Container>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Fade>
        <div className="main7-title">
          <p className="main7-title-text">공간샘 100개 지점</p>
          <div className="main7-underline-pink" />
        </div>
      </Fade>

      <p className="main7-sub-text">
        공간샘은 <span>독보적 학습 공간 기술과 무인시스템</span> 으로
        <br />
        <span>7개의 특허출원</span>을 내었고,
        <br className="show-480" /> <span>샘클라우드 기술</span>은
        <br className="hide-480" /> <span>전국 100개의 가맹점</span>을{" "}
        <br className="show-480" />
        <span>‘최고의 자기계발 공간’</span>
        으로 만들었습니다.
      </p>
      <div className="slide hide-480">
        <Slider {...settings}>
          {branchlist.map((list: any, index: number) => (
            <div className="city" key={index}>
              <p className="city-title">
                <span>{list.titlename}</span> {list.name}
              </p>
              <img src={list.image} alt={list.name} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="horizontal-slide show-480">
        <Slider {...settings2}>
          {branchlist.map((list: any, index: number) => (
            <div className="horizontal-item" key={index}>
              <img src={list.image} alt={list.name} />
              <p className="horizontal-item-title">
                <span>{list.titlename}</span>{" "}
                <span className="new-branch">신규</span>
                <br />
                {list.name}
              </p>
            </div>
          ))}
        </Slider>
      </div>
      <button
        className="city-list-btn"
        onClick={() => window.open("/branch", "_self")}
      >
        지점현황 바로가기
      </button>
    </Container>
  )
}

export default Main7
