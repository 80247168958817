import React from "react"
import styled from "styled-components"
import { Fade } from "react-awesome-reveal"

import Main8Table from "../../static/main8/main8-table.png"
import Main8Table_480 from "../../static/main8/main8-table-480.png"
import LeftArrow from "../../static/arrow(l).svg"
import RightArrow from "../../static/arrow(R).svg"
import Slider from "react-slick"

const Container = styled.div`
  padding: 6vw 0 0;
  background-color: #d96e62;

  .main8-text-part p {
    font-weight: bold;
    color: #fff;
    font-size: 2.3vw;
    text-align: center;
    line-height: 1.67;
  }

  .main8-text-part p span {
    font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans JP', sans-serif;
    font-size: 4vw;
    font-weight: 900;
  }

  .main8-slide-source iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .main8-slide-source {
    position: relative;
    width: 100vw;
    padding-bottom: 56.25%;
  }

  .slick-slide div {
    margin: 0 auto;
    width: 55vw;
  }

  .slick-prev {
    left: 19vw;
    z-index: 99;
  }

  .slick-next {
    right: 19vw;
    z-index: 99;
  }

  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  .main8-media-slide {
    margin: 5vw auto 10vw;
  }

  .main8-table-part img {
    margin: 2.5vw auto;
    width: 64vw;
    position: absolute;
    margin-top: -4vw;
    left: 50vw;
    margin-left: -32vw;
  }

  .table-background {
    width: 100vw;
    height: 62vw;
    background-color: #fff;
  }

  .p-padding {
    padding-top: 0;
  }

  .slick-dots {
    display: none !important;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }
  @media screen and (max-width: 480px) {
    .main8-text-part p span {
      font-size: 8vw;
      line-height: 1.25;
    }

    .main8-text-part p {
      font-size: 4.8vw;
    }

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .p-padding {
      padding-top: 2vw;
    }

    .slick-slide div {
      width: 68vw;
    }

    .slick-prev {
      left: 7vw;
      z-index: 99;
    }

    .slick-next {
      right: 7vw;
      z-index: 99;
    }

    .slick-dots {
      display: block !important;
    }
    .slick-dots li {
      width: 2vw;
    }

    .slick-dots li button:before {
      font-size: 2.5vw;
      opacity: 1;
      color: #fff;
    }
    .main8-table-part img {
      width: 90vw;
      margin-left: -45vw;
      margin-top: -8vw;
    }

    .table-background {
      height: 86vw;
    }

    .main8-media-slide {
      margin: 5vw auto 15vw;
    }

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }
  }
`

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "2vw",
        zIndex: 99,
        color: "#fff",
      }}
      onClick={onClick}
    >
      <img style={{ width: "2vw" }} src={RightArrow} alt="화살표" />
    </div>
  )
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        zIndex: 99,
        color: "#fff",
      }}
      onClick={onClick}
    >
      <img
        style={{ width: "2vw", float: "right" }}
        src={LeftArrow}
        alt="화살표"
      />
    </div>
  )
}

const settings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  dots: true,
}

const Main8 = () => {
  return (
    <Container>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Fade>
        <div className="main8-text-part">
          <p className="main8-title">
            <span>
              공간샘 전국 100개 가맹점
              <br className="show-480" /> 월 평균 매출 1200만원!
            </span>
            <br />
            <p className="p-padding">
              가맹점주님들께서 공간샘과 이뤄낸 성과입니다.
            </p>
          </p>
        </div>
      </Fade>
      <div className="main8-media-slide">
        <Slider {...settings}>
          <div className="main8-slide-source">
            <iframe src="https://www.youtube.com/embed/1Y1lypYny04?rel=0"></iframe>
          </div>
          <div className="main8-slide-source">
            <iframe src="https://www.youtube.com/embed/LPDhxvSJG2s?rel=0"></iframe>
          </div>
          <div className="main8-slide-source">
            <iframe src="https://www.youtube.com/embed/_wPRXIVP8m4?rel=0"></iframe>
          </div>
          <div className="main8-slide-source">
            <iframe src="https://www.youtube.com/embed/rG7X5TkzjOc?rel=0"></iframe>
          </div>
        </Slider>
      </div>
      <Fade>
        <div className="main8-table-part">
          <img className="hide-480" src={Main8Table} />
          <img className="show-480" src={Main8Table_480} />
          <div className="table-background" />
        </div>
      </Fade>
    </Container>
  )
}

export default Main8
