import React from "react"
import Layout from "../components/Layout/Layout"
import Main1 from "../components/main/main1"
import Main2 from "../components/main/main2"
import Main3 from "../components/main/main3"
import Main4 from "../components/main/main4"
import Main5 from "../components/main/main5"
import Main6 from "../components/main/main6"
import Main7 from "../components/main/main7"
import Main8 from "../components/main/main8"
import Main9 from "../components/main/main9"

import { graphql } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  overflow-x: hidden;
  font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans JP', sans-serif;

  .main1__Container-fimhHB {
    margin: 1vw 0 2vw;
  }
`
const IndexPage = ({ data }) => {
  const {
    allStrapiBranches: { nodes: branchlist },
  } = data
  return (
    <Container>
      <Layout>
        <Main1 />
        <Main2 />
        <Main3 />
        <Main4 />
        <Main5 />
        <Main6 />
        <Main7 branchlist={branchlist} />
        <Main8 />
        <Main9 />
      </Layout>
    </Container>
  )
}

export const query = graphql`
  {
    allStrapiBranches(sort: { fields: opendate, order: DESC }) {
      nodes {
        titlename
        name
        city
        address
        contact
        homepage
        image
        mapurl
        xpos
        ypos
        opendate
      }
    }
  }
`

export default IndexPage
