import React from "react"
import styled from "styled-components"
import Typewriter from "typewriter-effect"
import { Fade } from "react-awesome-reveal"

const Container = styled.div`
  padding: 5vw 0;
  background-color: #f7f7f7;
  border-bottom: dotted 1px #877c7a;
  .main3-title-text {
    font-weight: 900;
    font-size: 4vw;
    text-align: center;
  }

  .main3-title-text:nth-child(1) {
    margin-bottom: -1.4vw;
  }

  .main3-title-text:nth-child(3) {
    margin-top: 1.5vw;
    margin-bottom: -1.4vw;
  }

  .main3-underline-pink {
    width: 39vw;
    height: 1.2vw;
    background-color: rgba(217, 110, 98, 0.3);
    margin: 0 auto;
  }

  .main3-text {
    font-size: 2vw;
    color: #212121;
    text-align: center;
    margin-top: 4vw;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    padding: 10vw 0;
    .show-480 {
      display: block;
    }

    .main3-title-text {
      font-size: 7vw;
    }

    .main3-underline-pink {
      width: 67vw;
      height: 2.2vw;
    }

    .main3-text {
      font-size: 4.5vw;
      line-height: 1.38;
    }
  }
`

const Main3 = () => {
  return (
    <Container>
      <div className="main3-title">
        <p className="main3-title-text">온/오프라인 완전 장악,</p>
        <div className="main3-underline-pink" />
        <p className="main3-title-text">또 한번 혁신하는 공간샘</p>
        <div className="main3-underline-pink" />
      </div>
      <p className="main3-text">
        미래를 준비하는 공간,
        <br className="show-480" /> 함께 성장하는 사회를 만듭니다.
      </p>
    </Container>
  )
}

export default Main3
