import React from "react"
import styled from "styled-components"
import { Fade } from "react-awesome-reveal"

import Main5image from "../../static/main/main5.png"

const Container = styled.div`
  padding: 0 0 1vw;
  background-color: #f7f7f7;
  border-bottom: dotted 1px #877c7a;
  .main5-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main5-textPart {
    margin-left: 15vw;
    font-weight: bold;
    padding-bottom: 4vw;
  }

  .main5-mini-title {
    font-size: 1.7vw;
    color: #d96e62;
  }

  .main5-title {
    font-size: 3vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  .main5-text {
    font-size: 1.3vw;
    line-height: 1.79;
  }

  .main5-text span {
    color: #d96e62;
  }

  .main5-imagePart img {
    width: 52vw;
    margin-right: 7vw;
    margin-top: -2.5vw;
  }

  .show-480 {
    display: none;
  }
  .hide-480 {
    display: block;
  }

  @media screen and (max-width: 480px) {
    padding: 13vw 0;
    .show-480 {
      display: block;
    }
    .hide-480 {
      display: none;
    }

    .main5-textPart {
      margin-left: 0;
    }

    .main5-part {
      flex-direction: column;
      align-items: flex-start;
      width: 80vw;
      margin: auto;
    }

    .main5-mini-title {
      font-size: 4.3vw;
      color: #d96e62;
    }

    .main5-title {
      font-size: 6.5vw;
      margin-top: 2vw;
      margin-bottom: 2vw;
    }
    .main5-imagePart img {
      width: 90vw;
      margin-top: -21.5vw;
    }
    .main5-text {
      font-size: 4.3vw;
      font-weight: bold;
    }

    .main5-text span {
      color: #000000;
    }
  }
`

const Main5 = () => {
  return (
    <Container>
      <div className="main5-part">
        <Fade>
          <div className="main5-textPart">
            <p className="main5-mini-title">업계최초 관리형 스터디카페</p>
            <p className="main5-title">AI 캠스터디</p>
            <p className="main5-text hide-480">
              본사에서 직접 운영하는
              <br />
              <span>AI 캠스터디 &amp; 학습관리 시스템</span>으로
              <br />
              <span>관리형 스터디카페</span>를 실현화합니다
            </p>
          </div>
        </Fade>
        <Fade direction="up" delay={-200}>
          <div className="main5-imagePart">
            <img src={Main5image} alt="main5" />
          </div>
        </Fade>
        <Fade>
          <p className="main5-text show-480">
            본사에서 직접 운영하는
            <br />
            <span>
              AI 캠스터디 &amp; 학습관리
              <br />
              시스템
            </span>
            으로
            <span>관리형 스터디카페</span>를<br />
            실현화합니다.
          </p>
        </Fade>
      </div>
    </Container>
  )
}

export default Main5
