import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Slider from "react-slick"

import Cap1 from "../static/main-cap/cap1.png"
import Cap2 from "../static/main-cap/cap2.png"
import Cap3 from "../static/main-cap/cap3.png"
import Cap4 from "../static/main-cap/cap4.png"
import Cap5 from "../static/main-cap/cap5.png"
import Mainslide1 from "../static/main/main1slide.mp4"
import LeftArrow from "../static/arrow(l)2.svg"
import RightArrow from "../static/arrow(R)2.svg"

const Container = styled.div`
  video {
    width: 55.5vw;
    height: auto;
  }
  .title-slide {
    margin: 0vw 0 2vw;
  }
  .popup-media-size iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .popup-media-size {
    position: relative;
    width: 100vw;
    padding-bottom: 56.25%;
  }

  .title-slide .slick-list {
    width: 55.5vw;
    margin: 0 auto;
  }

  .slick-track {
    width: 56vw;
    margin: auto;
  }

  .title-slide .slick-track {
    margin: auto;
  }

  .popup-media-size2 {
    height: 7.5vw;
  }

  .popup-media-size2 img {
    width: 15vw;
    margin: auto;
  }

  .same-title-slide .slick-slider {
    width: 53vw;
    margin: 3vw auto;
  }

  .same-title-slide .slick-list {
    width: 48vw;
    margin: auto;
  }
  .slick-next,
  .slick-prev,
  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  .second-slick {
    border: solid 1px #d1d1d1;
    padding: 1vw;
    display: flex;
    justify-content: center;
  }

  .modal-medialist-text {
    font-size: 1.7vw;
    font-weight: bold;
    color: #212121;
    margin-bottom: 1vw;
  }
  @media screen and (max-width: 480px) {
    video {
      width: 75vw;
      height: auto;
    }
    .title-slide .slick-list {
      width: 75vw;
    }

    .same-title-slide .slick-list {
      width: 65vw;
    }

    .popup-media-size2 img {
      width: 20vw;
      height: 13vw;
    }

    .popup-media-size2 {
      height: 13vw;
    }

    .second-slick {
      padding: 1.4vw 0 0.4vw;
      width: 75vw;
      margin: 0 auto 2vw;
    }

    .modal-medialist-text {
      font-size: 3.7vw;
      width: 75vw;
      margin: 4vw auto 2.5vw;
    }
  }
`

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "2vw",
        zIndex: 99,
        color: "#555454",
        marginTop: "0.5vw",
      }}
      onClick={onClick}
    >
      <img style={{ width: "1vw" }} src={RightArrow} alt="화살표" />
    </div>
  )
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        zIndex: 99,
        color: "#555454",
        marginTop: "0.5vw",
      }}
      onClick={onClick}
    >
      <img
        style={{ width: "1vw", float: "right" }}
        src={LeftArrow}
        alt="화살표"
      />
    </div>
  )
}

const Main1 = () => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let slider1: any = []
  let slider2: any = []

  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
  }, [slider1, slider2])

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  return (
    <Container>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Slider
        className="title-slide"
        asNavFor={nav2}
        ref={slider => (slider1 = slider)}
      >
        <div>
          <video
            src="https://samstoragekr.s3.ap-northeast-2.amazonaws.com/saemcafe.com_main_video1.mp4"
            type="video/mp4"
            autoPlay
            muted
            controls
          ></video>
        </div>
        <div className="popup-media-size">
          <iframe src="https://www.youtube.com/embed/fczsu6zysKw"></iframe>
        </div>
        <div className="popup-media-size">
          <iframe src="https://www.youtube.com/embed/7sdnfeK5zRg"></iframe>
        </div>
        <div className="popup-media-size">
          <iframe src="https://www.youtube.com/embed/jGxtToqQrJQ"></iframe>
        </div>
        <div className="popup-media-size">
          <iframe src="https://www.youtube.com/embed/SeKG4iKIGvg"></iframe>
        </div>
      </Slider>
      <p className="modal-medialist-text">공간샘 영상목록 (5)</p>
      <div className="second-slick">
        <Slider
          className="same-title-slide"
          {...settings}
          asNavFor={nav1}
          ref={slider => (slider2 = slider)}
          slidesToShow={3}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          <div className="popup-media-size2">
            <img src={Cap5} alt="1" />
          </div>
          <div className="popup-media-size2">
            <img src={Cap1} alt="1" />
          </div>
          <div className="popup-media-size2">
            <img src={Cap2} alt="2" />
          </div>
          <div className="popup-media-size2">
            <img src={Cap3} alt="3" />
          </div>
          <div className="popup-media-size2">
            <img src={Cap4} alt="4" />
          </div>
        </Slider>
      </div>
    </Container>
  )
}

export default Main1
