import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Closebtn from "../static/closebtn.svg"
import Closebtn_480 from "../static/closebtn-480.svg"
function Modal({
  className,
  onClose,
  maskClosable,
  closable,
  visible,
  children,
}) {
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  return (
    <>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          {closable && (
            <button
              className="modal-close"
              onClick={close}
              style={{
                border: "none",
                fontSize: "3vw",
                margin: "0vw 23vw ",
                backgroundColor: "transparent",
              }}
            >
              <img
                className="hide-480"
                style={{ width: "2vw" }}
                src={Closebtn}
                alt="close"
              />
              <img
                className="show-480"
                style={{
                  width: "4vw",
                  position: "absolute",
                  marginTop: "10vw",
                  marginLeft: "69vw",
                }}
                src={Closebtn_480}
                alt="close"
              />
            </button>
          )}
          {children}
        </ModalInner>
      </ModalWrapper>
    </>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
}

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? "block" : "none")};
  position: fixed;
  margin-top: 0vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;

  @media screen and (max-width: 480px) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  background-color: trensparent;
  border-radius: 10px;
  width: 50vw;
  top: 0vw;
  margin: 0 auto;
  .show-480 {
    display: none;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    .show-480 {
      display: block;
    }

    .hide-480 {
      display: none;
    }
  }
`

export default Modal
