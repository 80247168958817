import React from "react"
import styled from "styled-components"
import Slider from "react-slick"

const Container = styled.div`
  padding: 7vw 0;
  background-color: #fafafa;

  .main9-underline-pink {
    width: 12.5vw;
    height: 1.2vw;
    background-color: rgba(217, 110, 98, 0.3);
    margin: -1vw 0 0 45.7vw;
  }

  .main9-text-part p {
    font-size: 2.2vw;
    font-weight: 600;
    text-align: center;
  }

  .main9-text-part span {
    font-weight: 900;
  }

  .main9-media-part {
    margin: 6vw auto 0;
    text-align: center;
  }

  .main9-media-source iframe {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .main9-media-source {
    position: relative;
    width: 25vw;
    height: 16vw;
    padding-bottom: 56.25%;
  }

  .main9-second-source {
    margin-left: 1vw;
  }

  .main9-media-under-text {
    font-size: 1.3vw;
    font-weight: bold;
    margin-top: 1.5vw;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .main9-text-part p {
      font-size: 7vw;
      font-weight: 900;
      line-height: 1.33;
    }

    .main9-underline-pink {
      width: 44.5vw;
      height: 3vw;
      margin: -4vw 0 0px 17vw;
    }

    .main9-slide-media iframe {
      position: absolute;
      left: 0;
      width: 100%;
      height: 76%;
    }

    .main9-slide-media {
      position: relative;
      width: 76vw;
      margin-top: 7vw;
      padding-bottom: 56.25%;
    }

    .main9-media-under-text {
      font-size: 4vw;
      font-weight: 500;
      margin: -8vw auto 6vw;
      text-align: center;
      margin-left: -19vw;
    }

    .main9-slide .slick-slide {
      width: 100vw !important;
      margin-left: -19vw;
    }

    .main9-slide .slick-list {
      width: 88vw;
      float: right;
    }
  }
`

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1000,
  cssEase: "linear",
  variableWidth: true,
  variableHeight: true,
}

const Main9 = () => {
  return (
    <Container>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <div className="main9-text-part">
        <p>
          심세용 대표의 <br className="show-480" />
          <span>공간샘의 비전</span>을 말하다.
        </p>
        <div className="main9-underline-pink" />
      </div>

      <table className="main9-media-part hide-480">
        <thead></thead>
        <tbody>
          <tr>
            <td>
              <div className="main9-media-source">
                <iframe src="https://www.youtube.com/embed/goNwmKATD_8?autoplay=1&mute=1&rel=0"></iframe>
              </div>
            </td>
            <td>
              <div className="main9-media-source main9-second-source">
                <iframe src="https://www.youtube.com/embed/NgCqfbsAFhI?rel=0"></iframe>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <p className="main9-media-under-text">
                선생님의 삶에서 창업가의 삶으로
              </p>
            </td>
            <td>
              <p className="main9-media-under-text">
                창업브랜드를 고를 때 '이것'을 확인하세요
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <Slider className="main9-slide show-480" {...settings}>
        <div className="main9-slide-item show-480">
          <div className="main9-slide-media">
            <iframe src="https://www.youtube.com/embed/goNwmKATD_8?autoplay=1&mute=1&rel=0"></iframe>
          </div>
          <p className="main9-media-under-text">
            선생님의 삶에서 창업가의 삶으로
          </p>
        </div>
        <div className="main9-slide-item show-480">
          <div className="main9-slide-media">
            <iframe src="https://www.youtube.com/embed/NgCqfbsAFhI?rel=0"></iframe>
          </div>
          <p className="main9-media-under-text">
            창업브랜드를 고를 때 '이것'을 확인하세요
          </p>
        </div>
      </Slider>
    </Container>
  )
}

export default Main9
